import React, { useState } from 'react'

const CookiePopUp = ({onAccept}) => {
  const [isHidden, setIsHidden] = useState(false);

  const handleAccept = () => {
    setIsHidden(true);
   
  };
  return (
    <div className="cookie-banner" id="cookie-banner"  style={{ display: isHidden ? 'none' : 'block' }}>
        <p>We use cookies to improve the performance of our website. By continuing to use the site, you agree to the use of cookies.</p>
        <button id="accept-cookies" onClick={handleAccept}>Submit</button>
    </div>
  )
}

export default CookiePopUp