import React, { useState } from 'react'
import agecheck from "../assets/images/age-check-icon.png"
const AgePopup = ({ onConfirm }) => {
  const [isHidden, setIsHidden] = useState(false);

  const handleConfirm = () => {
    setIsHidden(true);
   
  };

  console.log('onConfirm:', onConfirm, 'typeof:', typeof onConfirm);
  return (
    <div className={`age-check-popup ${isHidden ? 'hidden' : ''}`} id="age-check-popup">
      <div className="popup-inner-wrapper">
        <img src={agecheck} alt="age check icon" />
        <h3 className="text-center">
          Age Verification
        </h3>
        <p className="popup-text text-center">
          You must be 18 years or older to access this website.
          Please verify your age.
        </p>
        <button type="button" className="text-center popup-btn" onClick={handleConfirm}>
          I am at least 18 years old
        </button>
      </div>


    </div>
  )
}

export default AgePopup