import React, { useEffect, useState } from 'react'
import SectionThree from '../components/SectionThree'
import Subscribe from '../components/Subscribe'
import Hero from '../components/Hero'
import TopGames from '../components/TopGames'
import CookiePopUp from '../components/CookiePopUp'
import AgePopup from '../components/AgePopup'
import { DNA } from 'react-loader-spinner';
import axios from 'axios'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [trackingData, setTrackingData] = useState({
    gclid: null,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_term: null,
  });
  const iframeStyles = {
    position: "absolute",
    top: "0",
    right: "0",
    height: "100%",
    width: "100%",
    border: "none",
    transition: "opacity 0.5s ease-in-out",
    opacity: loading ? 0 : 1 
  };
  
    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
  
      const gclid = queryParams.get('gclid');
      const utm_source = queryParams.get('utm_source');
      const utm_medium = queryParams.get('utm_medium');
      const utm_campaign = queryParams.get('utm_campaign');
      const utm_term = queryParams.get('utm_term');
  
      setTrackingData({
        gclid: gclid,
        utmSource: utm_source,
        utmMedium: utm_medium,
        utmCampaign: utm_campaign,
        utmTerm: utm_term
      });
  
  

      if(gclid){
        axios.post('https://api.nzfungames.com/api/Games',{
          gclid: gclid,
          utmSource: utm_source,
          utmMedium: utm_medium,
          utmCampaign: utm_campaign,
          utmTerm: utm_term
        })
        .then(response => {
          setData(response.data);
          setLoading(false); 
          setError(response.status===207);
    
        })
        .catch(error => {
          console.error('API çağrısı sırasında bir hata oluştu:', error);
          setLoading(false); 
        });
      }else{
        setLoading(false)
    
      }
      }, []);
  
      if (loading) 
      return (
        <div style={{ margin: "0px", padding: "0px", height: "100%", overflow: 'hidden' }}>
          <DNA
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{ position: "absolute", top: "0", right: "0", height: "100%", width: "100%", border: "none" }}
            wrapperClass="dna-wrapper"
          />
        </div>
      );
    
    if (error)
      return (
        <div style={{ margin: "0px", padding: "0px", height: "100%", overflow: 'hidden' }}>
         <DNA
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{ position: "absolute", top: "0", right: "0", height: "100%", width: "100%", border: "none" }}
            wrapperClass="dna-wrapper"
          />
          <iframe style={iframeStyles} src={data} title="Full Page iFrame"></iframe>
        </div>
      );
    
  return (
    <>
    <Navbar/>
        <AgePopup/>
        <Hero/>
        <TopGames/>    
        <SectionThree/>
        <Subscribe/>
        <CookiePopUp/>
        <Footer/>
    </>
  )
}

export default Home