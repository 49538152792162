import React from 'react'
import img1 from "../assets/images/gamstop.png"
import img2 from "../assets/images/gamcare.png"
import img3 from "../assets/images/18plus.png"
import img4 from "../assets/images/begambleaware.png"
const SectionThree = () => {
  return (

<section className="info">
        <div className="container">
        <h2 className="info-title text-color-white mb-2">
    Welcome to nzfungames.com - Play free social slot machines online!
</h2>
<p className="info-text text-color-white">
    Are you ready to spin the reels and have fun? nzfungames.com is the place for you! We have an incredible selection of free online slot machines, all with vibrant graphics, exciting sound effects, and many chances to win big!
</p>
<p className="info-text text-color-white">
    With nzfungames.com, you don’t have to worry about risking your own money. We offer free slot machines with no financial stakes. That means you can experience the fun and excitement of slot machines completely risk-free.
</p>
<p className="info-text text-color-white">
    Our extensive selection of slot machines includes classic 3-reel slots, 5-reel slots, bonus slots, progressive jackpot slots, and much more. There’s something for everyone, from traditional fruit machines to the latest 3D slots. Each game has its own unique theme and special features, so you’ll always find something new and exciting to try.
</p>
<p className="info-text text-color-white">
    We also have a lively community of players who love to share tips and strategies. So if you’re looking for a friendly and helpful place to play slots, you’ve come to the right place!
</p>
<p className="info-text text-color-white">
    So what are you waiting for? Sign up now and join the fun at nzfungames.com!
</p>

            <div className="sponsors-block d-flex align-items-center justify-content-center">
                <img src={img1} alt="sponsor"/>
                <img src={img2} alt="sponsor"/>
                <img src={img3} alt="sponsor"/>
                <img src={img4} alt="sponsor"/>
            </div>
        </div>
    </section>
    
  )
}

export default SectionThree