import React from "react";
import img1 from "../assets/images/logo.png";
const Navbar = () => {
  return (
    <header>
      <div className="container">
        <a href="/">
          <img src={img1} alt="logo" />
        </a>
      </div>
    </header>
  );
};

export default Navbar;
