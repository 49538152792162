import React from 'react'
import CookiePopUp from '../components/CookiePopUp'
import Subscribe from '../components/Subscribe'
import SectionThree from '../components/SectionThree'
import Hero from '../components/Hero'
import Game from '../components/Game'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const GamePage = () => {
  return (
    <div>
      <Navbar/>
        <Hero/>
        <Game/>    
        <SectionThree/>
        <Subscribe/>
        <CookiePopUp/>
        <Footer/>
    </div>
  )
}

export default GamePage