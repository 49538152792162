import React from 'react'

const Hero = () => {
    return (
        <section className="hero-section min-vh-100">
            <div className="container text-center">
                <div className="content-block">
                    <h1 className="hero-title text-color-white mb-2">
                        Discover the world of JackPot Slot Machine on a free social casino gaming platform!
                    </h1>
                    <p className="hero-subtitle text-color-white mb-4">
                        Dive into an exciting adventure and uncover the secrets of the ancient pirates. This slot machine gives you a unique opportunity to try your luck in the quest for invaluable treasures. With its vibrant graphics, captivating sound effects, and numerous winning combinations, JackPot Slot Machine promises an unforgettable gaming experience.
                    </p>
                    <a href="#top-games" className="link-btn hero-link-btn">
                        Play Now
                    </a>
                    <p className="hero-text text-center mt-3 text-color-white">
                        No registration required!
                    </p>

                </div>
            </div>
        </section>
    )
}

export default Hero