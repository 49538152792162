import React from 'react'
import img1 from "../assets/images/p1.png"
const TopGames = () => {
  return (
    <section className="top-games" id="top-games">
        <div className="container">
            <div className="inner-wrapper d-flex align-items-stretch justify-content-start flex-wrap">
                <div className="top-games-card">
                    <img src={img1} alt="game card"/>
                        <h2 className="game-name text-center mt-3">
                            JackPot Slot Machine
                        </h2>
                        <a href="/play" className="game-card-link text-center link-btn">
                        Play Now
                        </a>
                </div>
            </div>
        </div>
    </section>
  )
}

export default TopGames